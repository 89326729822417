body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #1b262c;
  color: #f3f3f3;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  min-height: 100%;
  overflow-y: overlay;
  font-family: "Spline Sans", sans-serif;
}

a {
  text-decoration: none;
  color: #98bae7;
}
