
.BlogPost {
    display: flex;
    justify-content: center;
}

.BlogPost .blog-post {
    width: 760px;
    padding: 20px;
}

.BlogPost .blog-title {
    text-align: center;
    font-weight: 700;
}

.BlogPost .blog-published {
    text-align: center;
    padding-bottom: 2rem;
    font-weight: 300;
}


/* Styles for within each blog piece */

.BlogPost pre {
    margin-bottom: 12px;
    padding: 0.75rem;
}

.BlogPost .blog-content {
    line-height: 1.5;
}

.BlogPost .blog-image {
    max-width: 250px;
    height: auto;
}

.BlogPost p:first-child {
    margin-top: 0;
}

.BlogPost .section-title {
    font-size: 24px;
    font-weight: 700;
}

.BlogPost .text-section {
    margin: 12px 0;
    display: inline-block;
}

.BlogPost .code-info {
    padding-bottom: 8px;
    margin-bottom: 0 !important;
}

.BlogPost .section-title:not(:first-of-type) {
    padding-top: 1rem;
}

.BlogPost .center-image {
    margin: 10px auto;
}

.BlogPost .center {
    display: flex;
    justify-content: center;
}
