
.PageContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 3rem 0;
}

.side-bar {
    width: 180px;
    border-right: 1px solid #ccc;
    padding: 0 2rem;
}

.side-bar-name {
    text-align: right;
    font-size: 36px;
    font-weight: 700;
}

.side-bar-menu {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.side-bar-menu-item {
    padding-top: 1rem;
    letter-spacing: 0.03rem;
    font-size: 18px;
}

.side-bar-menu-item > a {
    color: #99aaaa;
}

.side-bar-menu-item.selected > a {
    color: #FDC57B;
}

.side-bar-picture {
    display: flex;
    justify-content: flex-end;
    padding: 2rem 0 0 0;
}

.side-bar-picture > img {
    width: 75%;
    border-radius: 6px;
}

.side-bar-menu-item:hover {
    cursor: pointer;
}

.content-wrapper {
    width: 740px;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    margin-bottom: 2rem;
}

.top-bar-left > h2 {
    font-size: 24px;
}

.top-bar-right {
    display: flex;
}

.top-bar-right > div {
    font-weight: bold;
    padding-left: 2rem;
    font-size: 18px;
    color: #99aaaa;
}

.top-bar-menu-item > a {
    color: #99aaaa;
}

.top-bar-menu-item.selected > a {
    color: #FDC57B;
}

@media screen and (min-width: 821px) {
    .top-bar {
        display: none;
    }
}

@media screen and (max-width: 820px) {
    .PageContainer {
        padding-top: 0;
    }

    .side-bar {
        display: none;
    }
}
