.info-section:first-of-type {
  padding-top: 0;
}

.info-section {
  padding-top: 1rem;
}

.info-section > h2 {
  font-weight: 700;
  font-size: 24px;
  margin-top: 8px;
  margin-bottom: 12px;
  color: #fdc57b;
}

.about-section > h2 {
  margin-top: 0;
}

.info-section-text {
  line-height: 2rem;
  font-size: 18px;
  font-weight: 300;
}

.recent-work-item {
  margin-top: 1rem;
}

.recent-work-title {
  display: flex;
  flex-direction: column;
  color: #dadada;
  font-weight: 600;
  font-size: 18px;
}

.recent-work-title > a {
  color: #dadada;
}

.recent-work-info {
  display: flex;
}

.recent-work-info > p {
  font-weight: 300;
  font-size: 16px;
  padding: 0;
  margin: 0;
  margin-top: 0.2rem;
}

.recent-work-subtext {
  color: #99aaaa;
  font-weight: 300;
  letter-spacing: 0.02rem;
  font-size: 16px;
  margin-top: 0.2rem;
}

.connect-item {
  display: flex;
  margin-bottom: 0.5rem;
}

.connect-item:last-of-type {
  margin-bottom: 0;
}

.connect-subtext {
  color: #99aaaa;
  font-size: 14px;
  padding-left: 0.5rem;
  letter-spacing: 0.02rem;
}

.about-section > .info-section-text {
  margin-top: -2px;
  font-size: 16px;
}

.projects-section {
  padding-top: 1rem;
}

.projects-section .recent-work-info {
  font-size: 16px;
}

.connect-section {
  padding-top: 1rem;
}

@media screen and (max-width: 820px) {
  .info-section > h2 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .about-section > .info-section-text {
    font-size: 14px;
  }

  .info-section-text {
    font-size: 14px;
  }

  .recent-work-title {
    font-size: 16px;
  }

  .recent-work-subtext {
    font-size: 14px;
    line-height: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .recent-work-info > p {
    font-size: 14px;
  }

  .projects-section .recent-work-info {
    font-size: 14px;
    margin-top: -4px;
  }

  .connect-item {
    margin-bottom: 4px;
  }
}
