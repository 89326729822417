
.BlogOverview .blog-overview-header {
    font-weight: 700;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 18px;
    color: #FDC57B;
}

.BlogOverview .blog-title {
    font-weight: 400;
    font-size: 18px;
    margin-top: 24px;
    color: #dadada;
}

.BlogOverview .blog-blurb {
    font-weight: 300;
    margin-top: 12px;
    line-height: 24px;
}

@media screen and (max-width: 820px) {
    .BlogOverview .blog-overview-header {
        font-size: 20px;
    }

    .BlogOverview .blog-title {
        font-size: 14px;
    }

    .BlogOverview .blog-blurb {
        font-size: 14px;
    }
}
